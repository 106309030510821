<template>
  <div>
    <Multiselect
      v-model="selection"
      style="margin-top: 0.5em;"
      :options="results"
      :optionsLimit="10"
      :allow-empty="false"
      track-by="id"
      label="display_name"
      :resetAfter="false"
      selectLabel=""
      selectedLabel=""
      deselectLabel=""
      :searchable="true"
      :placeholder="placeholder"
      :showNoResults="true"
      :loading="loading"
      :clearOnSelect="false"
      :preserveSearch="true"
      @search-change="search"
      @select="select"
    >
      <span slot="noResult">
        {{ $t('searchbars.noResult') }}
      </span>
      <span slot="noOptions">
        {{ $t('searchbars.noOptions') }}
      </span>
    </Multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

import { mapState, mapActions } from 'vuex';

export default {
  name: 'SearchUsers',

  components: {
    Multiselect
  },

  props: {
    resetAfter: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: 'Ajouter cette organisation à des groupes d\'organisations'
    },
    currentUsers: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },

  data() {
    return {
      selection: null,
      loading: false,
      text: null,
      results: []
    };
  },

  computed: {
    ...mapState('users', [
      'usersList'
    ])
  },

  watch: {
    currentUsers: {
      deep: true,
      handler(newValue) {
        this.results = this.usersList.filter(el => newValue.every(e => e.user.id !== el.id));
      }
    },

    text: function(newValue) {
      this.loading = true;
      this.SEARCH_USERS_LIST({
        text: newValue
      })
        .then(() => {
          if (newValue) {
            this.results = this.usersList
              .filter(el => this.currentUsers.every(e => e.user.id !== el.id))
              .map(el => {return {
                ...el,
                display_name: el.first_name.concat(' ', el.last_name)
              };});
          } else {
            this.results.splice(0);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  },

  methods: {
    ...mapActions('users', [
      'SEARCH_USERS_LIST'
    ]),

    search(text) {
      this.text = text;
    },

    select(e) {
      this.$emit('select', e);
    }
  }
};
</script>

